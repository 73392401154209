<template>
	<div class="wrap template">
		<div class="type">
			<div class="list">
				<div class="item" v-for="item in typeArr" :key="item">
					<span class="label">{{item == 1?'分类':item == 3?'节日':'标签'}}</span>
					<div class="item_list cl" v-if="item == 1">
						<div class="pice" :class="{'active': typeId == 0}" @click="typeId = 0">全部</div>
						<div class="pice" :class="{'active': typeId == o.id}" v-for="o in typeList" :key="o.id" @click="typeId = o.id">{{o.name}}</div>
						<div class="sub_list cl" v-if="typeSubList.length > 0">
							<div class="sub_item" :class="{'active': m.id == typeSubId}" v-for="m in typeSubList" :key="m.id" @click="typeSubId = m.id">{{m.name}}</div>
						</div>
					</div>
					<div class="item_list cl" v-if="item == 3">
						<div class="pice" :class="{'active': festivalId == 0}" @click="festivalId = 0">全部</div>
						<div class="pice" :class="{'active': festivalId == o.id}" v-for="o in festivalList" :key="o.id" @click="festivalId = o.id">{{o.name}}</div>
					</div>
					<div class="item_list cl" v-if="item == 5">
						<div class="pice" :class="{'active': tagId == 0}" @click="tagId = 0">全部</div>
						<div class="pice" :class="{'active': tagId == o.id}" v-for="o in tagList" :key="o.id" @click="tagId = o.id">{{o.name}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="tpl">
			<template v-if="list.length > 0">
				<div class="list cl">
					<div class="item" v-for="item in list" :key="item._id">
						<div class="cover">
							<img class="img" v-if="item.modelimg" :src="item.modelimg">
							<img class="no_cover" v-else src="@/assets/images/no-cover.jpg">
						</div>
						<div class="infor">
							<div class="title">{{item.fTitle?item.fTitle:'未命名'}}</div>
							<div class="quote">被引用{{item.quoteNum?item.quoteNum:0}}次</div>
						</div>
						<div class="btns">
							<router-link class="btn" :to="'/preview?identityType=admin&formId='+item._id">预览</router-link>
							<div class="line"></div>
							<router-link class="btn" to="/?">选用</router-link>
						</div>
					</div>
				</div>
				<el-pagination :current-page="pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="count" prev-text="上一页" next-text="下一页" background></el-pagination>
			</template>
			<noData v-else text="暂时还没有表单模板哦！"></noData>
		</div>
	</div>
</template>
<script>
import { get } from "/src/services/ajax.js";
import noData from "@/components/noData";
export default {
	components: {
		noData,
	},
	data(){
		return {
			typeArr: [1,3,5],
			typeList: [],
			typeSubList: [],
			festivalList: [],
			tagList: [],
			typeId: 0,
			typeSubId: 0,
			festivalId: 0,
			tagId: 0,
			list: [],
			pageNo: 0,
			pageSize: 0,
			count: 0,
			formType:''
		}
	},
	created(){
		this.formType = this.$route.query.formType
	},
	computed: {
		watchObj(){
			const {typeId,typeSubId,festivalId,tagId} = this;
			return {typeId,typeSubId,festivalId,tagId}
		}
	},
	watch:{
		typeId(){
			this.typeSubId = 0;
			this.getSubTypeList();
		},
		watchObj(){
			this.getTplList();
		}
	},
	mounted(){
		this.typeArr.forEach(item=>{
			this.getTypeList(item);
		})
		this.getTplList();
	},
	methods: {
		// 获取分类列表
		getTypeList(type){
			const _this = this;
			get('/formdesign/loadFormTplType.api',{type}).then(res => {
				if(res.code == 200){
					if(type == 1){
						_this.typeList = res.data.list;
					}else if(type == 3){
						_this.festivalList = res.data.list;
					}else if(type == 5){
						_this.tagList = res.data.list;
					}
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取二级分类列表
		getSubTypeList(){
			const _this = this;
			if(_this.typeId == 0){
				_this.typeSubList = [];
			}else{
				get('/formdesign/loadFormTplType.api',{type: 1,parentId: _this.typeId}).then(res => {
					if(res.code == 200){
						_this.typeSubList = res.data.list;
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}
		},
		// 获取模板数据列表
		getTplList(){
			const _this = this,
			vocationId = _this.typeId == 0?'':_this.typeId,
			vocationSonId = _this.typeSubId == 0?'':_this.typeSubId,
			festivalId = _this.festivalId == 0?'':_this.festivalId,
			tagId = _this.tagId == 0?'':_this.tagId;
			
			// let queryUrl="";
			// if(_this.formType == 'ques_vue'){
			// 	queryUrl="/formdesign/loadFormTplType.api";
			// }else if(_this.formType == 'form'){
			// 	queryUrl="/formdesign/loadFormTplType.api";
			// }else{
			// 	queryUrl="/formdesign/loadFormTplType.api";
			// }
			get('/formdesign/loadFormTpl.api',{vocationId,vocationSonId,festivalId,tagId}).then(res => {
				if(res.code == 200){
					_this.pageNo = res.data.pageNo;
					_this.pageSize = res.data.pageSize;
					_this.count = res.data.count;
					_this.list = res.data.list;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		}
	}
};
</script>
<style lang="less" scoped>
.template {
	display: flex;
	flex-direction: column;
	padding: 20px;
	background-color: #fff;
	.type {
		padding: 10px 30px;
		box-shadow: 0 2px 10px 0 rgba(122, 122, 122, 0.25);
		border-radius: 4px;
		background-color: #fff;
		.list{
			.item{
				display: flex;
				padding: 5px 0;
				.label{
					line-height: 50px;
					margin: 0 20px 0 0;
				}
				.item_list{
					flex: 1;
					padding: 10px 0;
					border-bottom: 1px solid #e5e5e5;
					.pice{
						float: left;
						line-height: 30px;
						padding: 0 10px;
						margin: 0 10px 0 0;
						cursor: pointer;
						&:hover{
							color: var(--theme-color);
						}
					}
					.active{
						color: var(--theme-color);
					}
					.sub_list{
						float: left;
						width: 100%;
						line-height: 24px;
						padding: 10px 0;
						background-color: #f5f5f5;
						.sub_item{
							float: left;
							padding: 0 10px;
							margin: 0 10px 0 0;
							cursor: pointer;
							&:hover{
								color: var(--theme-color);
							}
						}
					}
				}
				&:last-child{
					.item_list{
						border: none;
					}
				}
			}
		}
	}
	.tpl {
		flex: 1;
		margin: 30px -10px 0;
		overflow-y: auto;
		.list{
			.item{
				float: left;
				width: 240px;
				margin: 10px;
				border: 1px solid #e5e5e5;
				border-radius: 4px;
				background-color: #fff;
				overflow: hidden;
				.cover{
					height: 120px;
					overflow: hidden;
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
						transition: all .3s;
					}
					.no_cover{
						border-bottom: 1px solid #e5e5e5;
					}
				}
				.infor{
					padding: 14px;
					line-height: 24px;
					.title{
						color: #666;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.quote{
						font-size: 12px;
						color: #999;
					}
				}
				.btns{
					display: flex;
					padding: 10px;
					border-top: 1px solid #e5e5e5;
					.btn{
						flex: 1;
						text-align: center;
						&:hover{
							color: var(--theme-color);
						}
					}
					.line{
						width: 1px;
						height: 20px;
						background-color: #ddd;
					}
				}
				&:hover{
					box-shadow: 0 0 10px rgba(0, 0, 0, .2);
					.cover{
						.img{
							transition: all .3s;
							transform: scale(1.1);
						}
					}
				}
			}
		}
		::v-deep .el-pagination{
			padding: 20px 10px 10px;
			font-weight: normal;
			text-align: right;
			.el-pagination__total,
			.el-pagination__sizes{
				float: left;
				.el-input{
					.el-input__inner{
						&:hover{
							border-color: var(--theme-color);
						}
					}
				}
			}
			.el-pager{
				li:not(.disabled){
					&:hover{
						color: var(--theme-color);
					}
				}
				li:not(.disabled).active{
					background-color: var(--theme-color);
					&:hover{
						color: #fff;
					}
				}
			}
			.btn-prev,
			.btn-next{
				font-size: 12px;
				padding: 0 5px;
			}
		}
	}
}
</style>